.table-of-contents {
  position: relative;
  height: 100%;
  padding-bottom: 48px;

  &__content {
    position: sticky;
    top: $spacing-xl;
    max-height: calc(100vh - #{$spacing-xxl});
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cdcdcd;
    }
  }


  &__header {
    border-bottom: 1px solid #d2dade;
    margin-bottom: 1em;
  }

  &__button {
    width: 100%;
    padding: 0 !important;
    height: 100%;

    &.-is-active {
      .icon {
        transform: rotate(-180deg);
      }

    }
  }

  &__title {
    font-family: 'IBM';
    text-transform: uppercase;
    margin: 0;
    font-size: 0.875rem;
    width: 100%;

    padding: 17px 0;
    text-align: left;
  }

  &__list {
    @include remove-list-styles;
    @include font-size-sm;
    margin: 0;
    color: $link-color;

    &-item {
      padding: 12px 0;
    }
  }
}