.large-documents-page {
  margin-top: $spacing-lg;

  @include screen-md {
    margin-top: $spacing-xxl;
  }

  &--ingress {
    @include font-size-lg;
    margin-top: $spacing-lg;
    line-height: 1.5;

  }

  &--footer {
    margin-top: $spacing-xl;
  }

  h1 {
    margin: 0;
  }

  &--content {
    @include screen-lg {
      // Explicit widths to prevent width being affected by editors HTML with explisit widths
      width: calc(90vw - 348px);
      max-width: 774px;
      margin-left: auto;
      padding-left: 2em;
    }
  }
}