.content-with-sidebar {
  @include screen-lg {
    display: grid;
    grid-template-areas: 'content-with-sidebar-content content-with-sidebar-sidebar';
    grid-template-columns: minmax(0, 1fr) 318px;
    column-gap: 138px;
  }

  &--content {
    @include screen-lg {
      grid-area: content-with-sidebar-content;
      flex-grow: 1;
    }
  }

  &--sidebar {
    margin-top: $spacing-xl;

    &:empty {
      margin-top: 0;
    }

    @include screen-lg {
      grid-area: content-with-sidebar-sidebar;
      margin-top: 0;
    }
  }

  &--header {
    @include screen-lg {
      grid-area: content-with-sidebar-header;
      flex-grow: 1;
      margin-bottom: 4em;
    }
  }

  &.-theme-sidebar-left {
    display: flex;
    flex-direction: column;

    @include screen-lg {
      display: grid;
      grid-template-areas: 'content-with-sidebar-sidebar content-with-sidebar-content';
      grid-template-columns: 300px 1fr;
      column-gap: $spacing-xl; // NOTE: This needs to be repeated in order to work in IE for some reason
    }

    .content-with-sidebar--sidebar {
      margin-top: 0;
    }
  }

  &.-theme-large-documents {

    @include screen-lg {
      display: grid;
      grid-template-areas: 'content-with-sidebar-header content-with-sidebar-header'
        'content-with-sidebar-sidebar content-with-sidebar-content';
      grid-template-columns: 432px 1fr;
      grid-template-rows: auto 1fr;
      column-gap: 0px; // NOTE: This needs to be repeated in order to work in IE for some reason
    }
  }
}