.tibi-proposal-data {
    @include font-body;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    margin-bottom: $spacing-xl;

    @include screen-md-max {
        display: flex;
        flex-direction: column;
    }

    &--left,
    &--right {
        display: flex;
        align-items: flex-start;

        @include screen-md-max {
            flex-direction: column;
        }
    }

    &--left {
        grid-column-start: 1;

        .tibi-proposal-data {

            @include screen-md {

                &--label,
                &--value {
                    flex-basis: 50%;
                }
            }
        }
    }

    &--right {
        grid-column-start: 2;

        .tibi-proposal-data {
            @include screen-md {
                &--label {
                    flex-basis: 30%;
                }

                &--value {
                    flex-basis: 70%;
                }
            }
        }

    }

    &--label {
        font-weight: bold;
        margin-right: 1.5rem;

        &:after {
            content: ':';
        }
    }

    &--value {

        a {
            @include underline-animation-fill('span');
            text-decoration: none;
            color: $primary-grey3;

            &:not(:last-child):after {
                content: ', ';
            }
        }
    }


}