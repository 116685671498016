@mixin explicit-margin-until-max-width($max-width, $margin) {
  margin: 0 $margin;

  @media (min-width: ($max-width + ($margin * 2))) {
    margin: 0 auto;
  }
}

.content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $content-width-lg;

  @media print {
    max-width: none;
    width: none;
    margin: 0;
  }

  &.-theme-narrow {
    max-width: $content-width-sm;

    @include explicit-margin-until-max-width($content-width-sm, $spacing4);
  }

  &.-theme-medium {
    max-width: $content-width-md;
    margin: 0 $spacing4;

    @include explicit-margin-until-max-width($content-width-md, $spacing4);
  }

  &.-theme-wide {
    max-width: $content-width-xl;
    margin: 0 $spacing4;

    @include screen-md {
      margin: 0 32px;
    }

    @include screen-lg {
      @include explicit-margin-until-max-width($content-width-xl, $spacing-xl);
    }
  }
}
