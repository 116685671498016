// It strips the unit of measure and returns it
// -------------------------
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/*******************
## CONVERT TO REM ##
*******************/

@function convert-to-rem($value, $base-value: $font-size-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}
