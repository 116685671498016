$simpleHeaderWidth: $spacing5;

.simple-header {
  position: fixed;
  top: 0;
  z-index: 14;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: $simpleHeaderWidth;
  width: 100%;
  background: $primary-grey3;

  &--img {
    margin-left: $spacing2;
    width: fit-content;
    height: 20px;
  }

  @include screen-lg {
    justify-content: left;
    right: 0;
    height: 100vh;
    width: $simpleHeaderWidth;

    &--img {
      margin: $simpleHeaderWidth 0 0;
      position: fixed;
      width: unset;
      transform: rotate(90deg) translateY(-16px);
      transform-origin: left;
    }
  }
}

.-with-simple-header {
  margin-top: $simpleHeaderWidth;

  @include screen-lg {
    margin-top: 0;
    width: calc(100% - #{$simpleHeaderWidth});
  }
}
